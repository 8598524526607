import gql from "graphql-tag";

export const GET_MODULE_INITIAL_DATA = gql`
query GetModuleInitialValue($first: Int, $after: String) {
  companies(first: $first, after: $after) {
    pageInfo {
      hasNextPage,
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      cursor
      node {
        value: _id
        label: companyName
      }
    }  
  }
  privateModuleFeatureItems {
    name
    title
    identifier
  }
}    
`

export const ENABLE_MODULE_FOR_COMPANY = gql`
mutation EnableModule($companyId: String!, $moduleList: String!) {
  createEnableModule(input: {
    companyId: $companyId,
    moduleList: $moduleList
  }) {
    enableModule {
      response
    }
  }
}    
`

export const GET_ALL_ACTIVATIONS = gql`
query EnabledModuleList($first: Int, $after: String){
  enabledModuleCompanies(first: $first, after: $after, filter_hasEnabledFilters: "true"){
    totalCount
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        _id
        companyName
        privateModuleFeatureItems {
          name
          identifier
          title
        }
      }
    }
  }
}    
`

export const DISABLE_MODULE_FOR_COMPANY = gql`
mutation DisableModule($companyId: String!, $moduleIdentifier: Int!)
{
  createDisableModule(input: {companyId: $companyId, moduleIdentifier: $moduleIdentifier}) {
    disableModule {
      response
    }
  }
}
`