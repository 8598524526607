import gql from "graphql-tag";

export const GET_COMPANY_CHOICES = gql`
query GetCompanyChoices($first: Int, $after: String, $filter: String){
  companies(first: $first, after: $after, filter_keyword: $filter) {
    pageInfo {
      hasNextPage,
      hasPreviousPage
      endCursor
      startCursor
    }
    totalCount
    edges {
      cursor
      node {
        value: _id
        label: companyName
      }
    } 
  }
}    
`