<template>
  <div>
    <!-- Hero -->
    <base-page-heading title="Module activate">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="javascript:void(0)">Module activate</b-breadcrumb-item>
          <b-breadcrumb-item active>Lista</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->

    <!-- Page Content -->
    <div class="content">
      <base-block title="Lista de module activate" v-bind:subtitle="'Numar de activari: '+activations">
        <template #options>
          <button type="button" class="btn-block-option" @click="showEnabledModuleForm">
            <i class="si" v-bind:class="addContext ? 'si-arrow-up' : 'si-arrow-down'"></i> Activeaza modul
          </button>
        </template>
        <div v-if="addContext" class="mb-4">
          <b-form @submit.stop.prevent="onEnableModuleSubmit">
            <b-row>
              <b-col lg="4" offset-lg="1">
                <b-form-group>
                  <v-select required v-bind:class="{'is-invalid': $v.enableForm.company.$error}" data-label="Companie" @search="fetchCompanyOptions" :options="companyOptions" placeholder="Cauta dupa partener" v-model="$v.enableForm.company.$model" aria-describedby="company-feedback">
                    <span slot="no-options" @click="$refs.select.open = false">
                      Nu am gasit nici un rezultat
                    </span>
                  </v-select>
                  <b-form-invalid-feedback v-if="!$v.enableForm.company.required" id="company-feedback">
                    Campul este obligatoriu!
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group>
                  <v-select multiple required v-bind:class="{'is-invalid': $v.enableForm.module.$error}" data-label="Module" :options="enabledModulesChoices" placeholder="Selecteaza cel putin un modul" v-model="$v.enableForm.module.$model" aria-describedby="module-feedback">
                    <span slot="no-options" @click="$refs.select.open = false">
                      Nu am gasit nici un rezultat
                    </span>
                  </v-select>
                  <b-form-invalid-feedback v-if="!$v.enableForm.module.required" id="module-feedback">
                    Campul este obligatoriu!
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-button type="submit" variant="primary" block>
                  <i class="fa fa-fw fa-check-circle mr-1"></i> Activeaza
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <b-table-simple bordered striped table-class="table-vcenter">
          <b-thead head-variant="dark">
            <b-tr>
              <b-th class="d-sm-none" style="width: 80%;">Activari</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 50%;">Partener</b-th>
              <b-th class="d-none d-sm-table-cell" style="width: 50%;">Module activate</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-if="activationList.length <= 0 && loaded">
              <b-td class="d-md-none text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
              <b-td colspan="3" class="d-none d-sm-table-cell text-center">
                <b-alert variant="warning" show class="mt-3">
                  <p class="mb-0">
                    Nu am gasit nici un rezultat
                  </p>
                </b-alert>
              </b-td>
            </b-tr>
            <b-tr v-for="activation in activationList" :key="activation.node._id">
              <b-td class="d-sm-none" style="width: 85%;">
                <span class="text-dark font-weight-bolder">{{ activation.node.companyName }}</span>
                <hr/>
                <div v-bind:class="{'border-top mt-2 pt-2': index > 0 && activation.node.privateModuleFeatureItems.length > 0}" v-for="(module, index) in activation.node.privateModuleFeatureItems" v-bind:key="module.identifier">
                  <b-row>
                    <b-col xs="10">
                      <span class="text-primary">{{ module.title }}</span>
                    </b-col>
                    <b-col xs="2" class="text-right">
                      <b-button type="button" class="btn btn-sm btn-danger" @click="deleteActivation(activation.node._id, module.identifier, module.title, activation.node.companyName)">
                        <i class="fa fa-trash-alt"></i>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-w600 font-size-sm">
                <span class="text-dark font-weight-bolder">{{ activation.node.companyName }}</span>
              </b-td>
              <b-td class="d-none d-sm-table-cell font-size-md">
                <div v-bind:class="{'border-top mt-2 pt-2': index > 0 && activation.node.privateModuleFeatureItems.length > 0}" v-for="(module, index) in activation.node.privateModuleFeatureItems" v-bind:key="module.identifier">
                  <b-row>
                    <b-col md="9">
                      <span class="text-primary">{{ module.title }}</span>
                    </b-col>
                    <b-col md="3" class="text-right">
                      <b-button type="button" class="btn btn-sm btn-danger" @click="deleteActivation(activation.node._id, module.identifier, module.title, activation.node.companyName)">
                        <i class="fa fa-trash-alt"></i>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </base-block>
    </div>
    <infinite-loading @infinite="loadMore" ref="infiniteLoading">
      <div slot="spinner"><b-spinner variant="primary" label="Loading..."></b-spinner></div>
      <div slot="no-more"></div>
      <div slot="no-results"></div>
    </infinite-loading>
  </div>
</template>

<style lang="scss">
  @import './src/assets/scss/vendor/vue-color';
  @import '~vue-select/src/scss/vue-select';
  @import './src/assets/scss/vendor/vue-select';
</style>

<script>
import VueSelect from 'vue-select'
import {fleshErrorMessage} from "../../../error/server-error";
import {
  DISABLE_MODULE_FOR_COMPANY,
  ENABLE_MODULE_FOR_COMPANY,
  GET_ALL_ACTIVATIONS,
  GET_MODULE_INITIAL_DATA
} from "../../../constants/module-graphql";
import {GET_COMPANY_CHOICES} from "../../../constants/company-graphql";
import {ITEM_NUMBER_PER_PAGE} from "../../../constants/settings";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";
import InfiniteLoading from "vue-infinite-loading";

export default {
  name: "Module",
  components: {'v-select': VueSelect, InfiniteLoading},
  mixins: [validationMixin],
  data() {
    return {
      activations: 0,
      pageInfo: null,
      addContext: false,
      companyOptions: [],
      enabledModulesChoices: [],
      activationList: [],
      enableForm: {
        company: null,
        module: null
      },
      errors: {
        enableForm: {}
      },
      loaded: false,
    }
  },
  validations: {
    enableForm: {
      company: {
        required,
      },
      module: {
        required,
      }
    }
  },
  apollo: {
    companies: {
      query: GET_MODULE_INITIAL_DATA,
      fetchPolicy: 'no-cache',
      variables: {
        first: ITEM_NUMBER_PER_PAGE,
      },
      result (result) {
        let options = [];
        result.data.companies.edges.forEach(function (item) {
          options.push(item.node);
        });
        this.companyOptions = options;
        options = [];
        result.data.privateModuleFeatureItems.forEach(function (item) {
          options.push({'value': item.identifier, 'label': item.title});
        });
        this.enabledModulesChoices = options;
      },
      error (error) {
        fleshErrorMessage(error, this);
      }
    }
  },
  methods: {
    loadMore ($state) {
      this.$apollo.query({
        query: GET_ALL_ACTIVATIONS,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          after: (this.pageInfo) ? this.pageInfo.endCursor : null
        },
        fetchPolicy: 'no-cache',
      }).then((result) => {
        this.pageInfo = result.data.enabledModuleCompanies.pageInfo;
        this.activationList.push(...result.data.enabledModuleCompanies.edges);
        this.pageInfo.hasNextPage ? $state.loaded() : $state.complete();
        this.loaded = true;
        this.activations = result.data.enabledModuleCompanies.totalCount;
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    showEnabledModuleForm() {
      this.addContext = !this.addContext;
    },
    fetchCompanyOptions(search) {
      this.$apollo.query({
        query: GET_COMPANY_CHOICES,
        variables: {
          first: ITEM_NUMBER_PER_PAGE,
          filter: search,
        }
      }).then((result) => {
        let options = [];
        result.data.companies.edges.forEach(function (item) {
          options.push(item.node);
        });
        this.companyOptions = options;
      }).catch((error) => {
        fleshErrorMessage(error);
      });
    },
    removeServerErrors(form) {
      this.errors[form] = {};
    },
    onEnableModuleSubmit() {
      this.errors.enableForm = {};
      this.$v.enableForm.$touch()
      if (this.$v.enableForm.$anyError) {
        return;
      }
      let moduleList = [];
      this.$v.enableForm.module.$model.forEach((item) => {
        moduleList.push(item.value);
      });
      this.$apollo.mutate({
        mutation: ENABLE_MODULE_FOR_COMPANY,
        variables: {
          companyId: this.$v.enableForm.company.$model.value,
          moduleList: JSON.stringify(moduleList),
        }
      }).then(() => {
        this.flashMessage.info({message: "Activarea este salvata cu succes!"});
        this.$v.enableForm.company.$model = null;
        this.$v.enableForm.module.$model = null;
        this.addContext = false;
        this.$v.enableForm.$reset();
        this.loaded = false;
        this.activationList = [];
        this.pageInfo = null;
        this.activations = 0;
        this.$refs.infiniteLoading.stateChanger.reset();
        this.$refs.infiniteLoading.attemptLoad();
      }).catch((error) => {
        fleshErrorMessage(error, this);
      })
    },
    deleteActivation(id, moduleIdentifier, moduleName, partnerName) {
      this.$swal({
        icon: 'warning',
        title: 'Dezactivare modul',
        html: 'Esti sigur ca vrei sa ',
        showCancelButton: true,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary m-1',
          cancelButton: 'btn btn-secondary m-1'
        },
        confirmButtonText: 'Da!',
        cancelButtonText: 'Nu',
      }).then(result => {
        if (result.value) {
          this.$apollo.mutate({
            mutation: DISABLE_MODULE_FOR_COMPANY,
            variables: {
              'companyId': id,
              'moduleIdentifier': moduleIdentifier,
            }
          }).then(() => {
            this.flashMessage.info({message: 'Dezactivarea modulului '+moduleName+' pentru  partenerulul '+partnerName+' este realizata cu success!'});
            this.loaded = false;
            this.activationList = [];
            this.pageInfo = null;
            this.activations = 0;
            this.$refs.infiniteLoading.stateChanger.reset();
            this.$refs.infiniteLoading.attemptLoad();
          }).catch((error) => {
            fleshErrorMessage(error, this);
          })
        }
      })
    }
  }
}
</script>